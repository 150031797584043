.ql-editor {
  min-height: 200px;
}

.Polaris-Tabs__Panel {
  height: calc(100vh - 69px);
  overflow: auto;
}

.Polaris-Layout .Polaris-Layout__Section:nth-child(2) {
  position: sticky;
  top: 16px;
}

iframe {
  width: 100%;
  height: 100%;
}

.content-preview iframe {
  height: 500px;
}

.content-preview-modal iframe {
  height: 300px;
}

.accordion__panel {
  padding: 10px !important;
}

.item-criteria .Polaris-Card__Section {
  padding: 0;
}